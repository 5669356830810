<template>
  <b-card>
    <div>
      <!-- search input -->
      <div class="custom-search d-flex align-items-center justify-content-end">
        <div
          class="d-flex flex-column flex-sm-row align-items-center mb-1 justify-content-around"
        >
          <b-form-input
            v-on:keyup="onSearch"
            v-model.lazy="searchTerm"
            placeholder="Search..."
            type="text"
            class="d-inline-block mr-sm-1 mb-1 mb-sm-0"
          />
          <template v-if="$permissionAbility(ACCOUNT_CREATE, permissions)">
            <b-button
              class="flex-shrink-0"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              v-on:click="showModal"
            >
              Create
            </b-button>
          </template>
        </div>
      </div>

      <!-- table -->

      <vue-good-table
          styleClass="vgt-table table-custom-style striped"
        mode="remote"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-column-filter="onColumnFilter"
        @on-per-page-change="onPerPageChange"
        :totalRows="totalRecords"
        :isLoading.sync="isLoading"
        :rows="rows"
        :sort-options="{
          enabled: false,
          multipleColumns: true,
          initialSortBy: [
            { field: 'id', type: 'desc' },
            { field: 'name', type: 'desc' },
            { field: 'created_at', type: 'desc' },
          ],
        }"
        :columns="columns"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >
        <template slot="table-row" slot-scope="props">
          <template v-if="props?.column?.field === 'format_account_info'">
            <span
              >Account Name: <b>
              <template v-if="$permissionAbility(ACCOUNT_SHOW, permissions)">
                <b-link v-on:click="onShowPage(props.row.id)">
                  {{ props?.row?.name }}
                </b-link></template
              >
              <template v-else>
                {{ props?.row?.name }}
              </template>
              </b>
            </span>
            <div v-if="props?.row?.email" class="cell-with-description">
              <span> Email: <b>{{ props?.row?.email }} </b></span>
            </div>
            <div
              v-if="props?.row?.account_number"
              class="cell-with-description"
            >
              <span> Acc No. <b>{{ props?.row?.account_number }} </b></span>
            </div>
            <div
              v-if="props?.row?.account_holder_name"
              class="cell-with-description"
            >
              <span>
                Holder Name: <b>{{ props?.row?.account_holder_name }}</b>
              </span>
            </div>
            <div
              v-if="props?.row?.routing_number"
              class="cell-with-description"
            >
              <span> Routing No. <b>{{ props?.row?.routing_number }}</b> </span>
            </div>
          </template>

          <template v-if="props?.column?.field === 'format_account_number'">
            <span>{{
              props?.row?.account_number ? props?.row?.account_number : "N/A"
            }}</span>
          </template>
          <template v-if="props?.column?.field === 'format_balance'">
            <FormatCurrency :amount="props.row?.balance" :currency="props?.row?.currency" />
          </template>

          <template v-if="props?.column?.field === 'format_currency'">
            <FormatCurrency :type="'currency'" :amount="props.row?.balance" :currency="props?.row?.currency" />
          </template>
          <template v-if="props?.column?.field === 'format_account_type'">
            <b-badge pill variant="light-primary">{{
              formatAccountType(props?.row?.accountType)
            }}</b-badge>
          </template>

          <template v-if="props?.column?.field === 'format_email'">
            <b-link :href="'mailto:' + props?.row?.email">
              {{ props?.row?.email }}
            </b-link>
          </template>
          <template
            v-if="props?.column?.field === 'format_account_holder_name'"
          >
            <span>{{
              props?.row?.account_holder_name
                ? props?.row?.account_holder_name
                : "N/A"
            }}</span>
          </template>
          <template v-if="props?.column?.field === 'format_routing_number'">
            <span>{{
              props?.row?.routing_number ? props?.row?.routing_number : "N/A"
            }}</span>
          </template>
          <template v-if="props?.column?.field === 'format_status'">
            <template v-if="props?.row?.status">
              <b-badge pill variant="light-success">Active</b-badge>
            </template>
            <template v-else-if="!props?.row?.status">
              <b-badge pill variant="light-danger">Inactive</b-badge>
            </template>
          </template>
          <!-- Column: Action -->
          <span v-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>

                <template v-if="$permissionAbility(ACCOUNT_SHOW, permissions)">
                  <b-dropdown-item v-on:click="() => onShowPage(props.row.id)">
                    <feather-icon icon="EyeIcon" class="mr-50" />
                    <span>Show</span>
                  </b-dropdown-item>
                </template>

                <template v-if="$permissionAbility(ACCOUNT_EDIT, permissions)">
                  <b-dropdown-item v-on:click="onShow(props.row)">
                    <feather-icon icon="Edit2Icon" class="mr-50" />
                    <span>Edit</span>
                  </b-dropdown-item>
                </template>

                <template
                  v-if="$permissionAbility(ACCOUNT_DELETE, permissions)"
                >
                  <b-dropdown-item v-on:click="onDelete(props.row.id)">
                    <feather-icon icon="TrashIcon" class="mr-50" />
                    <span>Delete</span>
                  </b-dropdown-item>
                </template>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Showing 1 to </span>
              <b-form-select
                v-model="pageLength"
                :options="['10', '25', '50', '100', '500']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </div>

    <b-modal
      id="modal-account-info-form"
      centered
      :title="
        modelType == 'editModel'
          ? 'Edit Account Information '
          : 'Add Account Information'
      "
      hide-footer
      @hidden="hiddenModal"
      no-close-on-backdrop
      size="lg"
    >
      <validation-observer ref="accountInfoValidation">
        <b-form v-on:submit.prevent="validationForm">
          <!-- name -->
          <b-row>
            <b-col xs="12" md="6" lg="6">
              <b-form-group label="Bank Name " label-for="bank_name" class="required-label">
                <validation-provider
                  #default="{ errors }"
                  name="bank name"
                  vid="bank_name"
                  rules="required|max:255"
                >
                  <b-form-input
                    id="bank_name"
                    type="text"
                    v-model="bankName"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Enter Bank Name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col xs="12" md="6" lg="6">
              <!-- name -->
              <b-form-group label="Country " label-for="country_id" class="required-label">
                <ValidationProvider
                  name="country name"
                  v-slot="{ errors }"
                  vid="country_id"
                  rules="required"
                >
                  <v-select
                    id="country_id"
                    placeholder="Choose Country"
                    v-model="countryId"
                    :options="countryNameOption"
                    :reduce="(country) => country.id"
                    label="name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col xs="12" md="6" lg="6">
              <b-form-group label="Account Name " label-for="name" class="required-label">
                <validation-provider
                  #default="{ errors }"
                  name="account name"
                  vid="name"
                  rules="required|max:255"
                >
                  <b-form-input
                    id="name"
                    type="text"
                    v-model="name"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Enter Account Name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col xs="12" md="6" lg="6">
              <!-- email -->
              <b-form-group label="Email " label-for="email" class="required-label">
                <validation-provider
                  #default="{ errors }"
                  type="email"
                  name="email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="email"
                    v-model="email"
                    :state="errors.length > 0 ? false : null"
                    name="email"
                    placeholder="john@example.com"
                    class="custom-font"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group></b-col
            >
            <template v-if="modelType != 'editModel'">
              <b-col xs="12" md="6" lg="6">
                <b-form-group label="Currency " label-for="currency" class="required-label">
                  <ValidationProvider
                    name="Currency"
                    v-slot="{ errors }"
                    vid="currency"
                    rules="required"
                  >
                    <v-select
                      id="currency"
                      placeholder="Choose a Currency"
                      v-model="selectCurrency"
                      :options="currencyOption"
                      :reduce="(option) => option?.currency"
                      label="currency"
                      class="custom-font"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
            </template>

            <b-col xs="12" md="6" lg="6">
              <b-form-group label="Account Type " label-for="account_type_id" class="required-label">
                <ValidationProvider
                  name="account type"
                  v-slot="{ errors }"
                  vid="account_type_id"
                  rules="required"
                >
                  <v-select
                    id="account_type_id"
                    placeholder="Choose an Account Type"
                    v-model="accountTypeId"
                    :options="accountTypeOption"
                    :reduce="(country) => country.id"
                    label="name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group></b-col
            >

            <b-col xs="12" md="6" lg="6">
              <!-- account holder name -->
              <b-form-group
                label="Account Holder Name"
                label-for="account_holder_name "
              >
                <validation-provider
                  #default="{ errors }"
                  name="account holder name "
                  vid="account_holder_name "
                >
                  <b-form-input
                    id="account_holder_name "
                    type="text"
                    v-model="accountHolderName"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Enter Account Holder Name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group></b-col
            >
            <b-col xs="12" md="6" lg="6">
              <!-- Account Number -->
              <b-form-group label="Account Number" label-for="account_number">
                <validation-provider
                  #default="{ errors }"
                  name="account number"
                  vid="account_number"
                >
                  <b-form-input
                    id="account_number"
                    type="text"
                    v-model="accountNumber"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Enter Type Name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col xs="12" md="6" lg="6">
              <!-- account routing number-->
              <b-form-group label="Routing Number" label-for="routing_number ">
                <validation-provider
                  #default="{ errors }"
                  name="routing number "
                  vid="routing_number "
                >
                  <b-form-input
                    id="routing_number "
                    type="text"
                    v-model="routingNumber"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Enter Routing Number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col xs="12" md="6" lg="6">
              <!-- Swift Code -->
              <b-form-group label="Swift Code " label-for="swift_code">
                <validation-provider
                  #default="{ errors }"
                  name="swift code"
                  vid="swift_code"
                >
                  <b-form-input
                    id="swift_code"
                    type="text"
                    v-model="swiftCode"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Enter Swift Code"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col xs="12" md="6" lg="6"
              ><!-- IBAN Number -->
              <b-form-group label="IBAN Number" label-for="iban_number">
                <validation-provider
                  #default="{ errors }"
                  name="IBAN number"
                  vid="iban_number"
                >
                  <b-form-input
                    id="iban_number"
                    type="text"
                    v-model="ibanNumber"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Enter IBAN Name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col xs="12" md="6" lg="6">
              <!-- branch name-->
              <b-form-group label="Branch Name" label-for="branch_name ">
                <validation-provider
                  #default="{ errors }"
                  name="branch name "
                  vid="branch_name "
                >
                  <b-form-input
                    id="branch_name"
                    type="text"
                    v-model="branchName"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Enter Branch Name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col xs="12" md="6" lg="6">
              <!-- account type-->
              <b-form-group label="Status " label-for="status" class="required-label">
                <ValidationProvider
                  name="status"
                  v-slot="{ errors }"
                  vid="status"
                  rules="required"
                >
                  <v-select
                    id="status"
                    placeholder="Choose a Status"
                    v-model="accountStatus"
                    :options="accountStatusOption"
                    :reduce="(country) => country.value"
                    label="name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col xs="12" md="12" lg="12">
              <!-- details -->
              <b-form-group label="Details " label-for="details" class="required-label">
                <validation-provider
                  #default="{ errors }"
                  name="details"
                  vid="details"
                  rules="required"
                >
                  <b-form-textarea
                    id="details"
                    v-model="details"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Enter Detailed Information"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col xs="12" md="12" lg="12">
              <!-- loading button -->
              <template v-if="loading">
                <b-button class="float-right" variant="primary" disabled>
                  <b-spinner small />
                  Loading...
                </b-button>
              </template>

              <!-- submit button -->
              <template v-else>
                <b-button
                  type="submit"
                  class="float-right"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                >
                  Submit
                </b-button>
              </template>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BForm,
  BModal,
  BFormTextarea,
  BSpinner,
  VBTooltip,
  BLink,
  BRow,
  BCol,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, max } from "@validations";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import {
  ACCOUNT_SHOW,
  ACCOUNT_CREATE,
  ACCOUNT_EDIT,
  ACCOUNT_DELETE,
} from "@/helpers/permissionsConstant";
import FormatCurrency from '@/layouts/components/FormatCurrency.vue'

export default {
  name: "AccountsView",
  components: {
    FormatCurrency,
    BForm,
    BButton,
    BCard,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BSpinner,
    BFormTextarea,
    BLink,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      ACCOUNT_SHOW,
      ACCOUNT_CREATE,
      ACCOUNT_EDIT,
      ACCOUNT_DELETE,
      modelType: "",
      accountId: "",
      bankName: "",
      name: "",
      email: "",
      ibanNumber: "",
      swiftCode: "",
      selectCurrency: "",
      currencyOption: [],
      accountTypeId: "",
      accountTypeOption: [],
      countryId: "",
      countryNameOption: [],
      accountNumber: "",
      accountHolderName: "",
      routingNumber: "",
      branchName: "",
      balance: "",
      details: "",
      accountStatus: true,
      accountStatusOption: [
        { name: "Active", value: true },
        { name: "Inactive", value: false },
      ],

      pageLength: 10,
      columns: [
        {
          label: "Type",
          field: "format_account_type",
          formatFn: this.formatAccountType,
          sortable: false,
        },
        {
          label: "Account Info",
          field: "format_account_info",
          sortable: false,
        },

        {
          label: "Currency",
          field: "format_currency",
          sortable: false,
        },
        {
          label: "Balance",
          field: "format_balance",
          sortable: false,
        },

        {
          label: "Status",
          field: "format_status",
          // formatFn: this.formatStatus,
          sortable: false,
        },

        {
          label: "Created On",
          field: "created_at",
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: "",
      delayTimer: null,
      isLoading: false,
      loading: false,
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          { field: "id", type: "desc" },
          { field: "name", type: "desc" },
          { field: "created_at", type: "desc" },
        ],
        page: 1,
        perPage: 10,
      },
    };
  },

  computed: {
    ...mapGetters({
      permissions: "userModule/getPermissions",
    }),
  },

  async created() {
    try {
      const [accountTypes, countries, getCurrencies] = await Promise.all([
        this.getAccountTypes(),
        this.getCountries(),
        this.getCurrencies(),
      ]);

      this.countryNameOption = (countries?.data?.data).map((item) => {
        let name = item.name;

        return {
          name,
          id: item.id,
        };
      });
      // account types
      this.accountTypeOption = (accountTypes?.data?.data || []).map((item) => {
        let name = item.name;

        return {
          name,
          id: item.id,
        };
      });

      // getCurrencies
      this.currencyOption = (getCurrencies?.data?.data || []).map((item) => {
        return {
          currency: item?.currency,
        };
      });
    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          icon: "BellIcon",
          variant: "danger",
          text: error?.response?.data?.message,
        },
      });
    }
  },

  methods: {
    async getCurrencies() {
      return await this.$api.get("api/currencies/all");
    },
    async getCountries() {
      return await this.$api.get("api/country/all");
    },
    formatAccountType(value) {
      return value?.data?.name;
    },
    formatStatus(value) {
      if (value) {
        return "Active";
      }

      return "Inactive";
    },
    showModal() {
      this.$bvModal.show("modal-account-info-form");
    },
    hiddenModal() {
      this.modelType = "";
      this.$bvModal.hide("modal-account-info-form");
      this.resetModal();
    },
    resetModal() {
      this.accountId = "";
      this.bankName = "";
      this.name = "";
      this.email = "";
      this.countryId = "";
      this.accountId = "";
      this.accountTypeId = "";
      this.accountNumber = "";
      this.ibanNumber = "";
      this.swiftCode = "";
      this.accountHolderName = "";
      this.routingNumber = "";
      this.branchName = "";
      this.balance = "";
      this.accountStatus = true;
      this.details = "";
      this.selectCurrency = "";
      // this.currencyOption = [];
    },
    onShowPage(id) {
      this.$router.push({
        name: "admin-accounts-details",
        params: { id },
      });
    },
    async getAccountTypes() {
      return await this.$api.get("api/account-types/all");
    },
    async getAccountInfo(params) {
      return await this.$api.get("api/accounts?include=accountType,country", {
        params,
      });
    },
    async onShow(value) {
      this.modelType = "editModel";
      this.accountId = value?.id;
      this.bankName = value?.bank_name;
      this.swiftCode = value?.swift_code;
      this.ibanNumber = value?.iban_number;
      this.name = value?.name;
      this.email = value?.email;
      this.accountTypeId = value?.account_type_id;
      this.countryId = value?.country?.data?.id;
      this.accountNumber = value?.account_number;
      this.accountHolderName = value?.account_holder_name;
      this.routingNumber = value?.routing_number;
      this.branchName = value?.branch_name;
      this.balance = value?.balance;
      this.accountStatus = value?.status;
      this.details = value?.details;

      this.showModal();
    },
    async onDelete(id) {
      this.$swal({
        title: "Warning!",
        text: "Are You Sure You Want To Delete This?",
        icon: "warning",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Ok",
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.$api.delete(`/api/accounts/${id}`);

            this.loadItems();

            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "BellIcon",
                variant: "success",
                text: "Account Information Successfully Deleted",
              },
            });
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                variant: "danger",
                text: error?.response?.data?.message,
              },
            });
          }
        }
      });
    },

    onSearch() {
      if (this.delayTimer) {
        clearTimeout(this.delayTimer);
        this.delayTimer = null;
      }

      this.delayTimer = setTimeout(() => {
        this.loadItems();
      }, 1000);
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({
        sort: params,
      });
      this.loadItems();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },

    async loadItems() {
      try {
        const [accountInfo] = await Promise.all([
          this.getAccountInfo({
            show: this.serverParams.perPage,
            page: this.serverParams.page,
            sort: this.serverParams.sort,
            q: this.searchTerm,
          }),
        ]);

        const data = accountInfo?.data?.data;
        const meta = accountInfo?.data?.meta;

        this.totalRecords = meta?.pagination?.total;
        this.rows = data;
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },
    validationForm: async function () {
      this.$refs.accountInfoValidation.validate().then(async (success) => {
        if (success) {
          try {
            this.$refs.accountInfoValidation.reset();
            if (this.modelType == "editModel") {
              this.loading = true;
              await this.$api.put(`/api/accounts/${this.accountId}`, {
                bank_name: this.bankName,
                country_id: this.countryId,
                swift_code: this.swiftCode,
                iban_number: this.ibanNumber,
                name: this.name,
                email: this.email,
                account_type_id: this.accountTypeId,
                account_number: this.accountNumber,
                account_holder_name: this.accountHolderName,
                routing_number: this.routingNumber,
                branch_name: this.branchName,
                details: this.details,
                status: this.accountStatus,
              });
              this.loading = false;
              this.loadItems();
              this.hiddenModal();
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  variant: "success",
                  text: "Account Type Successfully Updated",
                },
              });
            } else {
              this.loading = true;

              await this.$api.post(
                "/api/accounts",

                {
                  bank_name: this.bankName,
                  country_id: this.countryId,
                  swift_code: this.swiftCode,
                  iban_number: this.ibanNumber,
                  name: this.name,
                  email: this.email,
                  account_type_id: this.accountTypeId,
                  account_number: this.accountNumber,
                  account_holder_name: this.accountHolderName,
                  routing_number: this.routingNumber,
                  branch_name: this.branchName,
                  details: this.details,
                  status: this.accountStatus,
                  currency: this.selectCurrency,
                }
              );
              this.loading = false;
              this.hiddenModal();

              this.loadItems();

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  variant: "success",
                  text: "Account Information Successfully Added",
                },
              });
            }
          } catch (error) {
            this.loading = false;
            if (error?.response?.data?.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: error?.response?.data?.message,
                },
              });
            }

            if (error?.response?.data?.errors) {
              this.$refs.accountInfoValidation.setErrors(
                error?.response?.data?.errors
              );
            }
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
.table-custom-style {
  font-size: 14px !important;
  white-space: nowrap !important;
  min-height: 140px !important;
  tr,
  th,
  td {
    vertical-align: left !important;
    text-align: left !important;
  }
}

.cell-with-description {
  margin: 8px 0px;
}
</style>
